@import "./theme.scss";

.treeobj:hover {
    box-shadow: 3px 3px 7px rgba(0,0,0,.05);
}

#treeNavbox .treeobj {
    max-width: 1200px;
}

#ghostModeSwitch {
    display: block;
    margin-bottom: 0.25rem;
}