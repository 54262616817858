@font-face {
    font-family: "Cera Pro";
    font-weight: 400;
    src: url("CeraPro.ttf");
}
@font-face {
    font-family: "Cera Pro";
    font-weight: 500;
    src: url("CeraProMedium.otf");
}
@font-face {
    font-family: "Cera Pro";
    font-weight: 600;
    src: url("CeraProBold.otf");
}

$light-grey: #f8f8f8;
$dark-grey: #777;
$accent: #2fbe5f;
$accent-light: rgba(47, 190, 95, 0.1450980392156863);
$accent-dark: #30aa59;
$notification-warning: #ffd900;
$notification-warning-light: rgba(255, 217, 0, 0.2);
$light-yellow: rgba(235, 212, 0, 0.1);
$light-yellow-text: #fb0;

$redaccent: #be3b2f;
$redaccent-light: rgba(190, 59, 47, 0.1450980392156863);

$primary: $accent;
$secondary: $accent-light;

$utilities: (
    "max-width": (
        responsive: true,
        property: max-width,
        values: (
            100: 100%,
            85: 85%,
            75: 75%,
            50: 50%,
            25: 25%,
        ),
    ),
);

@import "~bootstrap/scss/bootstrap";

.text-light {
    color: $accent-light !important;
}

body {
    font-family: "Cera Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
        "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
}

body,
html,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.w-15 {
    width: 15%;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-secondary {
    background-color: $accent-light !important;
}

.btn-discord {
    @include button-variant(#7289da, #7289da, #fff);
}
.btn-discord:hover {
    color: #fff;
}

.hover-tooltip {
    position: relative;
    display: inline-block;
}

.hover-tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: $dark;
    color: $light-grey;

    top: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */

    text-align: center;

    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
}


.hover-tooltip:hover .tooltip-text {
    visibility: visible;
}