@import "./theme.scss";

.questionBox {
    max-width: 1400px;
}

.questionBox ul {
    list-style-type: none;
}

.questionBox :is(table,th,td) {
    border: 1px solid black;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.calcStatus {
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: $accent-light;
    border-radius: 4px;
    color: $accent;
    font-weight: 400;
    font-size: 1rem;
}

.math-inline, .math-inline .katex-display, .math-inline .katex-display * {
    display: inline-block;
}

ol {
    display: block;
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 0.5rem;
    width: 100%;
    padding-right: 2rem;
}

ol li {
    margin-left: 10px;
    display: list-item;
    counter-increment: questionCount 1;
}

ol li p {
    display: inline;
}

ol li::marker {
    list-style-position: inside;
    padding: 2px;
    content: "(" counter(questionCount, lower-alpha) "). ";
}

ol > li > ol {
    counter-reset: subQuestionCount 0;
}

ol > li > ol > li {
    counter-increment: subQuestionCount 1;
    display: list-item;
}

ol > li > ol > li::marker {
    content: "(" counter(subQuestionCount, lower-roman) "). ";
}

.math-outer-box {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.05);
    overflow-x: hidden;
}

.math-box {
    font: 1.21em / 1.4 KaTeX_Main, "Times New Roman", serif;
    width: calc(100% - 30px);
    position: relative;
    counter-reset: questionCount 0;
}

$sidebar-anim-duration: 1s;

.math-sidebar {
    position: absolute;
    display: flex;
    top: 0;
    right: -40%;
    width: 40%;
    z-index: 2;
    background: $light-grey;
    height: 100%;

    transition: $sidebar-anim-duration;
}

.math-sidebar-open {
    right: 0%;
}

.math-sidebar-opener {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    z-index: 3;

    color: $primary;
    background: $accent-light;

    display: flex;

    transition: $sidebar-anim-duration;
}

.math-sidebar-opener-opened {
    right: calc(40%);
}

@media only screen and (min-width: 1200px) {
    .math-sidebar {
        display: flex;
        width: 15%;
        right: 0;
        z-index: 2;
    }

    .math-sidebar-opener {
        display: none;
    }

    .math-box {
        width: 85%;
    }
}

.marks {
    position: absolute;
    float: right;
    vertical-align: bottom;
    right: 0;
}

.markscheme-modal {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.disallowCalc {
    background-color: $redaccent-light;
    color: $redaccent;  
}

.allowCalc {
    background-color: $accent-light;
    color: $accent;
}

.unknownCalc {
    background-color: rgba(178, 186, 187, 0.1450980392156863);
    color: #B2BABB;
}

$easy: #12B76A;
$medium: rgb(247, 144, 9);
$hard: #F04438;

.diff-bar {
    border-radius: 60px;
    background-color: #fff;
    width: 100%;
    height: 8px;
}

.diff-bar.diff-easy {
    border: 1px solid $easy;
}
.diff-bar.diff-medium {
    border: 1px solid $medium;
}
.diff-bar.diff-hard {
    border: 1px solid $hard;
}

.diff-box {
    border-radius: inherit;
    height: 100%;
}

.diff-box.diff-easy {
    background-color: $easy;
}
.diff-box.diff-medium {
    background-color: $medium;
}
.diff-box.diff-hard {
    background-color: $hard;
}